import {ApolloClient} from 'apollo-client';
import {ApolloLink} from 'apollo-link';

import cache from './cache';
import authLink from './link-auth';
import errorLink from './link-error';
import restLink from './link-rest';
import uploadLink from './link-upload';
import {defaultOptions} from './options';

const client = new ApolloClient({
  cache,
  defaultOptions,
  connectToDevTools: true,
  resolvers: {},
  link: ApolloLink.from([authLink, restLink, errorLink, uploadLink]),
});

export default client;
