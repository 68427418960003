// @flow

import AsyncStorage from '@callstack/async-storage';

import lodash from 'lodash';
import React, {useCallback, useContext} from 'react';

import client from '_client';
import {clearAuthToken, setAuthToken} from '_qclient';
import CURRENT_USER from '_queries/currentUser';
import {whitelist} from '_utils/auth';

// import {useAuthState} from './state';
import type {Dispatch, SignInType} from './types';

export const AuthDispatchContext = React.createContext<?Dispatch>();

export const useAuth = () => {
  const dispatch = useContext(AuthDispatchContext);
  if (!dispatch) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  // const {type: currentSignInType} = useAuthState();

  const signIn = useCallback(
    (token: string, type?: SignInType = 'RA') =>
      (async () => {
        await AsyncStorage.setItem('labs_2020_token', token);
        setAuthToken(token);
        const data = await client.query({query: CURRENT_USER});
        if (data) {
          const {me} = data;
          const auth = lodash.find(whitelist, {email: me.email});
          const authUser = {...me, ...auth};
          dispatch({type: 'SIGN_IN', payload: {user: authUser, type}});
          return me;
        }
        throw new Error('Could not sign in user');
      })(),
    [dispatch],
  );

  const signOut = useCallback(
    () =>
      (async () => {
        await AsyncStorage.removeItem('labs_2020_token');
        clearAuthToken();
        dispatch({type: 'SIGN_OUT'});
      })(),
    [dispatch],
  );

  return {signIn, signOut};
};
