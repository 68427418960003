// @flow

import React, {useContext} from 'react';

import type {Action, State} from './types';

export const DEFAULT_STATE = {
  currentUser: undefined,
  type: undefined,
};

export const AuthStateContext = React.createContext<State>(DEFAULT_STATE);

export const stateReducer = (state?: State, action: Action) => {
  switch (action.type) {
    case 'MOUNT': {
      if (action.payload) {
        const {user, type} = action.payload;
        return {
          ...state,
          currentUser: user,
          type,
        };
      }
      break;
    }
    case 'SIGN_IN': {
      if (action.payload) {
        const {user, type} = action.payload;
        return {
          ...state,
          currentUser: user,
          type,
        };
      }
      break;
    }

    case 'SIGN_OUT':
      return {
        ...state,
        ...DEFAULT_STATE,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const useAuthState = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return context;
};

export default DEFAULT_STATE;
