// @flow
import {LocationProvider} from '@reach/router';

import React from 'react';
import {ApolloProvider} from 'react-apollo';
import {QueryClientProvider} from 'react-query';

import client from '_client';
import Layout from '_components/Layout';
import {AuthProvider} from '_contexts/auth';
import queryClient from '_qclient/queryClient';
import {MovementPatternsContainer} from '_store/movementPatterns';

const Root = (): React$Node => (
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={client}>
      <LocationProvider>
        <AuthProvider>
          <MovementPatternsContainer isGlobal>
            <Layout />
          </MovementPatternsContainer>
        </AuthProvider>
      </LocationProvider>
    </ApolloProvider>
  </QueryClientProvider>
);

export default Root;
