import gql from 'graphql-tag';

const CURRENT_USER = gql`
  query currentUser {
    me {
      id
      email
      firstName
      lastName
    }
  }
`;

export default CURRENT_USER;
