// FIXME: should be removed once all has been changed to use `useMovementPatterns`
import gql from 'graphql-tag';

export const GET_MOVEMENT_PATTERNS = gql`
  query getMovementPatterns {
    movementPatterns {
      id
      name
      order
      exercises {
        id
        name
        level
        videoUrl
        equipment
        primaryMuscleGroups
        secondaryMuscleGroups
        guidance
        halfwayCue
        movementPatternId
        timerName
        active
        assetKey
        repType
        weighted
        workoutTypes
      }
    }
  }
`;

export default GET_MOVEMENT_PATTERNS;
