// @flow
import {GraphQLClient} from 'graphql-request';

const getDefaultHeaders = () => {
  const headers = {
    'X-Application-ID': '12a2362f-86e7-4209-8a27-0f07adc24c5b',
  };

  return headers;
};

const client = new GraphQLClient(process.env.REACT_APP_GRAPH_URL, {
  headers: getDefaultHeaders(),
});

export function clearAuthToken() {
  client.setHeader('authorization', '');
}

export function setAuthToken(token: string) {
  if (token) {
    client.setHeader('authorization', `Bearer ${token}`);
  } else {
    clearAuthToken();
  }
}

export default client;
