import {setContext} from 'apollo-link-context';

const applicationId = '12a2362f-86e7-4209-8a27-0f07adc24c5b';

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('labs_2020_token') || null;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Application-ID': applicationId,
    },
  };
});

export default authLink;
