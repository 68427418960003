import {InMemoryCache, IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';

import introspectionQueryResultData from './fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Todo: remap natural keys to handle cache
const cache = new InMemoryCache({
  fragmentMatcher,
});

export default cache;
