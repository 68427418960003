// @flow
import React from 'react';

import SpinnerFS from '_components/SpinnerFS';
import {useAuthState} from '_contexts/auth';

const LayoutPrivate = React.lazy(() => import('_components/Layout/private'));
const LayoutPublic = React.lazy(() => import('_components/Layout/public'));

function Layout() {
  const {currentUser} = useAuthState();

  return <React.Suspense fallback={<SpinnerFS />}>{currentUser ? <LayoutPrivate /> : <LayoutPublic />}</React.Suspense>;
}

export default Layout;
