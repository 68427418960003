import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

/* eslint-disable camelcase */
import translation_en from './translations/en.json';
import translation_es from './translations/es.json';
import translation_pt_BR from './translations/pt_BR.json';
/* eslint-enable camelcase */

const resources = {
  en: {translation: translation_en},
  es: {translation: translation_es},
  'pt-BR': {translation: translation_pt_BR},
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
