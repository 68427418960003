// @flow

import React from 'react';

import FullScreen from '_components/FullScreen';
import RitualLoading from '_static/loaders/loading-ritual-black.gif';

const SpinnerFS = () => (
  <FullScreen>
    <img src={RitualLoading} alt="Ritual Loading.." />
  </FullScreen>
);

export default SpinnerFS;
