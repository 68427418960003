// @flow

import {
  type Action,
  type ContainerComponent,
  type HookFunction,
  createContainer,
  createHook,
  createStore,
} from 'react-sweet-state';

import client from '_client';
import {GET_MOVEMENT_PATTERNS} from '_queries/getMovementPatterns';

import {type Actions, type State, type Store} from './types';

const initialState: State = {
  loading: true,
  movementPatterns: null,
};

const actions = {
  update: (): Action<Store> => () => {},
};

const store = createStore<State, Actions>({
  initialState,
  actions,
});

export const useMovementPatterns: HookFunction<State, Actions> = createHook(store);

export const MovementPatternsContainer: ContainerComponent<{}> = createContainer(store, {
  onInit:
    () =>
    async ({setState}) => {
      const res = await client.query({
        query: GET_MOVEMENT_PATTERNS,
      });

      setState({
        loading: false,
        movementPatterns: res.data.movementPatterns,
      });
    },
});

export default MovementPatternsContainer;
