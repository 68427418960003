// @flow

import AsyncStorage from '@callstack/async-storage';

import lodash from 'lodash';
import type {ComponentType, Node} from 'react';
import React, {useEffect, useReducer} from 'react';

import client from '_client';
import SpinnerFS from '_components/SpinnerFS';
import useAsync from '_hooks/useAsync';
import CURRENT_USER from '_queries/currentUser';
import {whitelist} from '_utils/auth';

import {AuthDispatchContext, useAuth} from './dispatch';
import {AuthStateContext, DEFAULT_STATE, stateReducer, useAuthState} from './state';

async function setup() {
  let me = null;
  const token = await AsyncStorage.getItem('labs_2020_token');
  if (token) {
    const {data} = await client.query({query: CURRENT_USER});
    const auth = lodash.find(whitelist, {email: data.me.email});
    me = {...data.me, ...auth};
  }
  return me;
}

type Props = {
  children: Node,
};

const AuthProvider = ({children}: Props) => {
  const [state, dispatch] = useReducer(stateReducer, DEFAULT_STATE);
  const {data: user, status, isLoading, isIdle, isSuccess, run} = useAsync();

  useEffect(() => {
    const appDataPromise = setup();
    run(appDataPromise);
  }, [run]);

  const value = React.useMemo(() => ({user}), [user]);

  useEffect(() => {
    dispatch({type: 'MOUNT', payload: value});
  }, [value]);

  if (isLoading || isIdle) {
    return <SpinnerFS />;
  }

  if (isSuccess && state) {
    return (
      <AuthStateContext.Provider value={state}>
        <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
      </AuthStateContext.Provider>
    );
  }

  throw new Error(`Unhandled status: ${status}`);
};

const withAuth = (component: ComponentType<any>) => (props: any) => React.createElement(component, props);

export {AuthProvider, withAuth, useAuth, useAuthState};
