// Sets the default policies for Query/Mutate. To override these, set them manually on each Query/Mutate use.
export const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

export default defaultOptions;
